import React, { useContext, useEffect, useRef, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { FaFileImport, FaCopy } from "react-icons/fa";
import { TbCircleCheckFilled } from "react-icons/tb";
import * as pdfjsLib from "pdfjs-dist";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import PropOpen from "./PropOpen";
import { BsThreeDots } from "react-icons/bs";
import { GoHomeFill } from "react-icons/go";
import { BiSolidPlusSquare } from "react-icons/bi";
import { FaFile } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import CanvasContext from "../../../../context/CanvasContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DialogBox from "./DialogBox";
import Page from "./Page";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs";

const Pages = () => {
  const [isCoppied, setIsCoppied] = useState(false);
  const [file, setFile] = useState(null);
  const [pdfFile, setPDFFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isPDFImporting, setIsPDFImporting] = useState(false);
  const { siteRecord, setSiteRecord, setPagesState } =
    useContext(CanvasContext);
  const [newPage, setNewPage] = useState("");
  const [initCreate, setInitCreate] = useState(false);
  const inputRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // const URL = `https://eb65-2400-adc5-476-fa00-f1ab-f839-c13f-64b9.ngrok-free.app/webhook/zapier/${siteRecord._id}`
  // const URL = `https://staging-api.magicweb.ai/webhook/zapier/${siteRecord._id}`;
  const URL = `https://api.makeweb.ai/webhook/zapier/${siteRecord._id}`;

  useEffect(() => {
    if (initCreate && inputRef.current) {
      inputRef.current.focus();
    }
  }, [initCreate, inputRef]);
  const axiosPrivate = useAxiosPrivate();

  const [isCreating, setIsCreating] = useState(false);

  const createNewBlogTemplatePage = async () => {
    const pagePath = "blog-template";
    setIsCreating(true);
    try {
      const resp = await axiosPrivate.post("/website/page", {
        websiteId: siteRecord._id,
        path: pagePath,
        code: {
          compName: "div",
          compProps: {},
          eventProps: {},
          className:
            "w-full min-h-[700px] bg-background text-foreground font-body flex flex-col items-center relative",
          style: {},
          children: [
            {
              compName: "section",
              compProps: {},
              eventProps: {},
              className: "w-full min-h-[700px] px-6 py-16",
              style: {},
              children: [
                {
                  compName: "section",
                  compProps: {},
                  eventProps: {},
                  className: "max-w-3xl min-h-[700px] mx-auto p-6 md:p-12",
                  style: {},
                  children: [],
                },
              ],
            },
          ],
        },
      });

      if (resp.status == 201 && resp.data?.code) {
        const { code, title, description } = resp.data;
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[pagePath] = { code, title, description };
          return { ...site, devPages };
        });

        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[pagePath] = undefined;
          return newStates;
        });
        setSearchParams({ page: pagePath });
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (error) {
    } finally {
      setIsCreating(false);
      setNewPage("");
      setInitCreate(false);
    }
  };

  const createNewPage = async (pagePath) => {
    pagePath = pagePath.replace(/\s+/g, "-"); // replaces spaces with hyphen
    setIsCreating(true);
    try {
      const resp = await axiosPrivate.post("/website/page", {
        websiteId: siteRecord._id,
        path: pagePath,
        code: {
          compName: "div",
          compProps: {},
          className:
            "w-screen min-h-screen bg-background text-foreground font-body flex flex-col items-center relative",
        },
      });
      if (resp.status == 201 && resp.data?.code) {
        const { code, title, description } = resp.data;
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[pagePath] = { code, title, description };
          return { ...site, devPages };
        });
        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[pagePath] = undefined;
          return newStates;
        });
      } else {
        throw Error("Unexpected response from server");
      }
    } catch (err) {
      // setSitesError(err.mesaage);
    } finally {
      setIsCreating(false);
      setNewPage("");
      setInitCreate(false);
    }
  };
  // Handler for input changes
  const handleInputChange = (e) => {
    const value = e.target.value;
    const pattern = /^[a-zA-Z0-9 ]*$/; // Pattern to allow only letters and numbers

    // Check if the current input value matches the pattern
    if (pattern.test(value)) {
      setNewPage(value); // Update the state if the value is valid
    } else {
      // Optionally, you can provide feedback or handle invalid input here
      console.log("Invalid input: Only letters and numbers are allowed.");
    }
  };

  const handlePDFFileUpload = async (pdfFile) => {
    setIsPDFImporting(true);
    const formData = new FormData();
    formData.append("file", pdfFile);

    formData.append(
      "data",
      JSON.stringify({
        websiteId: siteRecord._id,
      })
    );

    try {
      const response = await axiosPrivate.post(
        "/website/pdf/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setSiteRecord(response.data.data);
        setSearchParams({ page: response.data.pagePath });
      } else {
        throw Error("Unexpected Response from server");
      }
    } catch (error) {
      console.error("Error uploading PDF file:", error);
      alert("Failed to upload PDF file.");
    }
    setIsPDFImporting(false);
  };

  const handlePDFFileChange = (event) => {
    if (!event.target.files[0]) return;
    const selectedPDFFile = event.target.files[0];

    if (
      selectedPDFFile &&
      (selectedPDFFile.type === "application/pdf" ||
        selectedPDFFile.name.endsWith(".pdf"))
    ) {
      setPDFFile(selectedPDFFile);

      handlePDFFileUpload(selectedPDFFile);
    } else {
      alert("Please upload a valid PDF file.");
    }
    event.target.value = null;
  };

  const handleFileChange = (event) => {
    if (!event.target.files[0]) return;

    const selectedFile = event.target.files[0];
    // Check if file is an Excel file based on its MIME type
    if (
      selectedFile &&
      (selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type === "text/csv" ||
        selectedFile.name.endsWith(".csv"))
    ) {
      setFile(selectedFile);

      handleFileUpload(selectedFile);
    } else {
      alert("Please upload a valid Excel file.");
    }
    event.target.value = null;
  };

  const handleFileUpload = async (file) => {
    setIsImporting(true);
    const formData = new FormData();
    formData.append("file", file);

    formData.append(
      "data",
      JSON.stringify({
        websiteId: siteRecord._id,
      })
    );

    try {
      const response = await axiosPrivate.post(
        "/website/blog/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setSiteRecord(response.data.data);
      } else {
        throw Error("Unexpected Response from server");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }
    setIsImporting(false);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsCoppied(true);
          setTimeout(() => {
            setIsCoppied(false);
          }, 1500);
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setIsCoppied(true);
        setTimeout(() => {
          setIsCoppied(false);
        }, 1500);
      } catch (error) {
        console.error("Failed to copy text: ", error);
      }
      document.body.removeChild(textArea);
    }
  };

  // const extractPdfContentWithMetadata = async (file) => {
  //   console.log("inside extractPdfContentWithMetadata", file);
  //   const pdf = await pdfjsLib.getDocument(file).promise;
  //   console.log("pdfpdf", pdf);
  //   const content = [];

  //   for (let i = 1; i <= pdf.numPages; i++) {
  //     const page = await pdf.getPage(i);
  //     const textContent = await page.getTextContent();

  //     // Extract text items with metadata
  //     textContent.items.forEach((item) => {
  //       content.push({
  //         text: item.str,
  //         transform: item.transform, // Includes font size and position
  //         width: item.width,
  //         height: item.height,
  //         fontName: textContent.styles[item.fontName]?.fontFamily,
  //       });
  //     });
  //   }

  //   console.log("contentttt", content); // Text with metadata
  //   return content;
  // };

  // const classifyContent = (content) => {
  //   console.log("contentinsidelassifyContent", content);

  //   const structuredContent = [];
  //   let currentList = null;
  //   let lastItemXPosition = null;

  //   content.forEach((item) => {
  //     const [fontSize] = item.transform;
  //     const xPosition = item.transform[4]; // Horizontal position (x)

  //     // Detecting bullet points or numbered lists
  //     if (
  //       item.str.startsWith("•") ||
  //       item.str.startsWith("-") ||
  //       /^[0-9]+\./.test(item.str.trim())
  //     ) {
  //       if (!currentList) {
  //         // Start a new list when a list label is found
  //         currentList = { type: "List", title: "", items: [] };
  //         structuredContent.push(currentList);
  //       }
  //       currentList.items.push({ text: item.str, xPosition });
  //     } else if (fontSize > 20) {
  //       // Detect Title based on font size
  //       structuredContent.push({ type: "Title", text: item.str });
  //     } else if (fontSize > 15) {
  //       // Detect Heading based on font size
  //       structuredContent.push({ type: "Heading", text: item.str });
  //     } else {
  //       // Paragraph
  //       if (currentList && currentList.items.length > 0) {
  //         // Check for possible indentation or continuation of list items
  //         if (Math.abs(xPosition - lastItemXPosition) < 10) {
  //           currentList.items.push({ text: item.str, xPosition });
  //         }
  //       }
  //       structuredContent.push({ type: "Paragraph", text: item.str });
  //     }

  //     // Update last item x position
  //     lastItemXPosition = xPosition;
  //   });

  //   console.log("structure23dContent", structuredContent);

  //   return structuredContent;
  // };

  const classifyContent = (content) => {
    const structuredContent = [];
    let currentList = null;

    content.forEach((item) => {
      const [fontSize] = item.transform;
      const xPosition = item.transform[4]; // Horizontal position (x)
      const text = item.str.trim();

      // Detecting bullet points or numbered lists
      if (text.startsWith("●")) {
        // New main bullet point

        console.log("currentListcurrentList", currentList);
        console.log("texttext", text);

        if (currentList) {
          structuredContent.push(currentList); // Push previous list to structured content
        }
        currentList = { type: "List", title: text, items: [] };
        currentList.items.push({
          text: text.slice(1).trim(),
          isSubBullet: false,
        });
      } else if (text.startsWith("○")) {
        // Sub-bullet point under the previous main bullet point
        if (currentList) {
          currentList.items.push({
            text: text.slice(1).trim(),
            isSubBullet: true,
          });
        }
      } else if (fontSize > 20) {
        // Title based on font size
        structuredContent.push({ type: "Title", text: text });
      } else if (fontSize > 15) {
        // Heading based on font size
        structuredContent.push({ type: "Heading", text: text });
      } else {
        // Paragraph
        if (currentList && currentList.items.length > 0) {
          // Adding to the previous list if items exist
          structuredContent.push(currentList);
          currentList = null;
        }
        structuredContent.push({ type: "Paragraph", text: text });
      }
    });

    // Push any leftover list at the end
    if (currentList && currentList.items.length > 0) {
      structuredContent.push(currentList);
    }

    console.log("structuredContent", structuredContent);

    return structuredContent;
  };

  const extractTextStructure = async (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = async (event) => {
        try {
          const arrayBuffer = event.target.result;
          const uint8Array = new Uint8Array(arrayBuffer);

          const pdfDocument = await pdfjsLib.getDocument(uint8Array).promise;
          const structuredContent = [];

          // Thresholds for classification and grouping
          const TITLE_FONT_SIZE = 20;
          const HEADING_FONT_SIZE = 15;
          const SUBHEADING_FONT_SIZE = 13;
          const LINE_SPACING_THRESHOLD = 15; // Vertical spacing threshold for grouping
          const TAB_WIDTH = 40; // Define a tab width for indentation levels

          for (
            let pageNumber = 1;
            pageNumber <= pdfDocument.numPages;
            pageNumber++
          ) {
            const page = await pdfDocument.getPage(pageNumber);
            const textContent = await page.getTextContent();
            const pageData = [];

            // Extract text spans with bounding boxes and font details
            textContent.items.forEach((item) => {
              const { str: text, transform, fontName } = item;
              const [x, y] = transform.slice(4, 6); // x, y coordinates
              const fontSize = item.height; // Approximation of font size
              const bbox = { x, y, width: item.width, height: fontSize };
              pageData.push({ text, bbox, fontSize, fontName });
            });

            // Group and classify text
            let currentElement = null;

            pageData.forEach((line) => {
              const { text, bbox, fontSize } = line;
              const { x, y } = bbox;

              // Determine the number of tabs based on the x coordinate
              const tabs = Math.floor(x / TAB_WIDTH);

              // Classify text based on font size and symbols
              let contentType = "paragraph";
              let listLevel = null;

              // Bullet points
              if (/^●/.test(text)) {
                contentType = "bullet";
                listLevel = "bullet"; // Main bullet
              } else if (/^○/.test(text)) {
                contentType = "sub-bullet";
                listLevel = "sub-bullet"; // Sub bullet
              } else if (/^■/.test(text)) {
                contentType = "sub-sub-bullet";
                listLevel = "sub-sub-bullet"; // Sub-sub bullet

                // Ordered lists
              } else if (/^\d+\./.test(text)) {
                contentType = "list";
                listLevel = "main-list"; // Main list
              } else if (/^[a-z]\./.test(text)) {
                contentType = "sub-list";
                listLevel = "sub-list"; // Sub-list
              } else if (/^(i|ii|iii|iv|v|vi|vii|viii|ix|x)\./.test(text)) {
                contentType = "sub-sub-list";
                listLevel = "sub-sub-list"; // Sub-sub list

                // Titles, headings, and subheadings
              } else if (fontSize >= TITLE_FONT_SIZE) {
                contentType = "title";
              } else if (fontSize >= HEADING_FONT_SIZE) {
                contentType = "heading";
              } else if (fontSize >= SUBHEADING_FONT_SIZE) {
                contentType = "subheading";
              }

              // Handle grouping of multi-line elements
              if (currentElement && currentElement.type === contentType) {
                const prevBBox = currentElement.bbox;
                const verticalSpacing = y - prevBBox.y - prevBBox.height;

                if (verticalSpacing <= LINE_SPACING_THRESHOLD) {
                  // Append to current element
                  currentElement.text += ` ${text}`;
                  currentElement.bbox = {
                    x: Math.min(prevBBox.x, x),
                    y: prevBBox.y,
                    width: Math.max(
                      prevBBox.width,
                      x + bbox.width - prevBBox.x
                    ),
                    height: prevBBox.height + bbox.height + verticalSpacing,
                  };
                  return;
                }
              }

              // Finalize the current element and start a new one
              if (currentElement) {
                structuredContent.push(currentElement);
              }

              currentElement = {
                type: contentType,
                text,
                listLevel, // Include list hierarchy level
                fontSize,
                bbox,
                tabs, // Include the number of tabs (indentation)
              };
            });

            // Push the last element of the page
            if (currentElement) {
              structuredContent.push(currentElement);
            }
          }

          console.log("structuredContent_in_func", structuredContent);

          resolve(structuredContent);
        } catch (error) {
          reject(error);
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsArrayBuffer(file);
    });
  };

  //   const fileReader = new FileReader();

  //   return new Promise((resolve, reject) => {
  //     fileReader.onload = async (event) => {
  //       try {
  //         const arrayBuffer = event.target.result;
  //         const uint8Array = new Uint8Array(arrayBuffer);

  //         const pdfDocument = await pdfjsLib.getDocument(uint8Array).promise;
  //         const structuredContent = [];

  //         // Thresholds for classification and grouping
  //         const TITLE_FONT_SIZE = 20;
  //         const HEADING_FONT_SIZE = 15;
  //         const SUBHEADING_FONT_SIZE = 13;
  //         const LINE_SPACING_THRESHOLD = 15; // Vertical spacing threshold for grouping
  //         const BULLET_ALIGNMENT_THRESHOLD = 20; // Horizontal alignment difference for bullets

  //         for (
  //           let pageNumber = 1;
  //           pageNumber <= pdfDocument.numPages;
  //           pageNumber++
  //         ) {
  //           const page = await pdfDocument.getPage(pageNumber);
  //           const textContent = await page.getTextContent();
  //           const pageData = [];

  //           // Extract text spans with bounding boxes and font details
  //           textContent.items.forEach((item) => {
  //             const { str: text, transform, fontName } = item;
  //             const [x, y] = transform.slice(4, 6); // x, y coordinates
  //             const fontSize = item.height; // Approximation of font size
  //             const bbox = { x, y, width: item.width, height: fontSize };
  //             pageData.push({ text, bbox, fontSize, fontName });
  //           });

  //           // Group and classify text
  //           let currentElement = null;

  //           pageData.forEach((line) => {
  //             const { text, bbox, fontSize } = line;
  //             const { x, y } = bbox;

  //             // Classify text based on font size and symbols
  //             let contentType = "paragraph";
  //             let listLevel = null;

  //             // Bullet points
  //             if (/^●/.test(text)) {
  //               contentType = "bullet";
  //               listLevel = "bullet"; // Main bullet
  //             } else if (/^○/.test(text)) {
  //               contentType = "sub-bullet";
  //               listLevel = "sub-bullet"; // Sub bullet
  //             } else if (/^■/.test(text)) {
  //               contentType = "sub-sub-bullet";
  //               listLevel = "sub-sub-bullet"; // Sub-sub bullet

  //               // Ordered lists
  //             } else if (/^\d+\./.test(text)) {
  //               contentType = "list";
  //               listLevel = "main-list"; // Main list
  //             } else if (/^(i|ii|iii|iv|v|vi|vii|viii|ix|x)\./.test(text)) {
  //               contentType = "sub-sub-list";
  //               listLevel = "sub-sub-list"; // Sub-sub list

  //               // Titles, headings, and subheadings
  //             } else if (/^[a-z]\./.test(text)) {
  //               contentType = "sub-list";
  //               listLevel = "sub-list"; // Sub-list
  //             } else if (fontSize >= TITLE_FONT_SIZE) {
  //               contentType = "title";
  //             } else if (fontSize >= HEADING_FONT_SIZE) {
  //               contentType = "heading";
  //             } else if (fontSize >= SUBHEADING_FONT_SIZE) {
  //               contentType = "subheading";
  //             }

  //             // Handle grouping of multi-line elements
  //             if (currentElement && currentElement.type === contentType) {
  //               const prevBBox = currentElement.bbox;
  //               const verticalSpacing = y - prevBBox.y - prevBBox.height;

  //               if (verticalSpacing <= LINE_SPACING_THRESHOLD) {
  //                 // Append to current element
  //                 currentElement.text += ` ${text}`;
  //                 currentElement.bbox = {
  //                   x: Math.min(prevBBox.x, x),
  //                   y: prevBBox.y,
  //                   width: Math.max(
  //                     prevBBox.width,
  //                     x + bbox.width - prevBBox.x
  //                   ),
  //                   height: prevBBox.height + bbox.height + verticalSpacing,
  //                 };
  //                 return;
  //               }
  //             }

  //             // Finalize the current element and start a new one
  //             if (currentElement) {
  //               structuredContent.push(currentElement);
  //             }

  //             currentElement = {
  //               type: contentType,
  //               text,
  //               listLevel, // Include list hierarchy level
  //               fontSize,
  //               bbox,
  //             };
  //           });

  //           // Push the last element of the page
  //           if (currentElement) {
  //             structuredContent.push(currentElement);
  //           }
  //         }

  //         console.log("structuredContent_in_func", structuredContent);

  //         resolve(structuredContent);
  //       } catch (error) {
  //         reject(error);
  //       }
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };

  //     fileReader.readAsArrayBuffer(file);
  //   });
  // };

  const processPdfFile = async (file) => {
    if (!file || file.type !== "application/pdf") {
      console.error("The provided file is not a valid PDF.");
      return;
    }

    try {
      // Create a FileReader to read the file as an ArrayBuffer
      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        const arrayBuffer = event.target.result;

        // // Pass the ArrayBuffer directly to PDF.js
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

        // // console.log(`PDF Loaded: ${file.name}`);
        // // console.log(`Number of pages: ${pdf.numPages}`);

        // const content = [];

        // for (let i = 1; i <= pdf.numPages; i++) {
        //   const page = await pdf.getPage(i);
        //   const textContent = await page.getTextContent();
        //   textContent.items.forEach((item) => {
        //     const [fontSize] = item.transform; // Approximation of font size
        //     // console.log("Text:", item.str);
        //     // console.log("Font Size:", fontSize);
        //   });

        //   content.push(...textContent.items);

        //   const structuredContent = classifyContent(content);

        //   console.log(`Page ${i} Text Content:`);
        //   console.log("textContenttextContent", textContent);
        //   // textContent.items.forEach((item) => {
        //   //   console.log(item.str);
        //   // });
        // }

        const combinedData = [];

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const content = await page.getTextContent();

          // Process content items
          const pageData = [];
          let currentParagraph = "";

          content.items.forEach((item) => {
            const text = item.str.trim();
            const fontSize = item.transform[0]; // Approximation of font size
            console.log("texttext", text);
            if (text.startsWith("●")) {
              console.log("texttextinbyllet", text);

              // Main bullet point
              pageData.push({
                type: "BulletPoint",
                level: "Main",
                text: text.replace("●", "").trim(),
              });
            } else if (text.startsWith("○")) {
              // Sub bullet point
              pageData.push({
                type: "BulletPoint",
                level: "Sub",
                text: text.replace("○", "").trim(),
              });
            } else if (text.startsWith("■")) {
              // Sub-sub bullet point
              pageData.push({
                type: "BulletPoint",
                level: "SubSub",
                text: text.replace("■", "").trim(),
              });
            } else if (fontSize > 20) {
              // Likely a title
              pageData.push({ type: "Title", text });
            } else if (fontSize > 14) {
              // Likely a subtitle or heading
              pageData.push({ type: "SubTitle", text });
            } else if (text) {
              // Assume it's part of a paragraph
              currentParagraph += ` ${text}`;
            }
          });

          // Push remaining paragraph text
          if (currentParagraph) {
            pageData.push({ type: "Paragraph", text: currentParagraph.trim() });
          }

          combinedData.push(...pageData);
        }

        console.log("combinedDatacombinedData", combinedData);
      };

      // Read the file as an ArrayBuffer
      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing PDF file:", error);
    }
  };

  const handlePDFChange = async (e) => {
    const file = e.target.files[0];
    // const pdfContent = await processPdfFile(file);
    extractTextStructure(file);
  };

  return (
    <div
      className="flex flex-col gap-5 tracking-wider pb-5 "
      title="Your website pages"
    >
      <div className="flex justify-between">
        <p className="text-zinc-200 font-semibold text-xs">Pages</p>
        <button
          onClick={() => setInitCreate(true)}
          title="Click to create a new page"
        >
          <FiPlus className="w-4 h-auto text-zinc-300" />
        </button>
      </div>
      <div className="flex flex-col w-full gap-0">
        {console.log("siteRecordsiteRecord", siteRecord)}
        {siteRecord?.devPages &&
          Object.keys(siteRecord.devPages)
            .sort()
            .map((page) => {
              if (siteRecord.devPages[page])
                return (
                  <Page key={page} page={page} isImporting={isImporting} />
                );
            })}
        {initCreate && (
          <div
            className={`col-span-3 text-zinc-400 text-xs w-full rounded-lg mt-2 py-2 pl-4 border border-lime-600`}
          >
            <div className="flex items-center gap-2">
              <FaFile className="w-3 h-auto" />
              /
              <input
                ref={inputRef}
                type="text"
                value={newPage}
                onChange={handleInputChange}
                placeholder="page-path"
                className="bg-transparent outline-none placeholder:text-zinc-500"
                onBlur={() => {
                  setInitCreate(false);
                  setNewPage("");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") createNewPage(newPage);
                }}
              />
            </div>
          </div>
        )}

        <div className="flex justify-between mt-5">
          <p className="text-zinc-200 font-semibold text-xs">Import Blog</p>
          <div>
            <input
              type="file"
              accept=".xlsx, .xls, .csv, application/vnd.ms-excel, text/csv"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              {isImporting ? (
                <AiOutlineLoading3Quarters className="float-right text-white w-4 h-auto animate-spin" />
              ) : (
                <button
                  className="float-right"
                  title="Click to upload file"
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  <FaFileImport className="w-4 h-auto text-zinc-300" />
                </button>
              )}
            </label>
            <button onClick={handleFileUpload}>Upload</button>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <p className="text-zinc-200 font-semibold text-xs">Zaiper Webhook</p>
        </div>
        <div
          className={`relative text-zinc-400 text-xs w-full rounded-lg group bg-zinc-900 mt-2`}
        >
          <div className="py-2 pl-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="w-32 whitespace-nowrap overflow-clip overflow-ellipsis">
                {URL}
              </span>
              <FaCopy
                onClick={() => copyToClipboard(URL)}
                className="w-4 h-auto cursor-pointer float-right ml-[25px]"
              />
            </div>
          </div>
        </div>
        {isCoppied ? (
          <small className="flex justify-end items-center gap-[5px] text-white mt-1 text-right w-full">
            <TbCircleCheckFilled className="text-lime-600" /> Coppied
          </small>
        ) : null}

        <div className="flex justify-between mt-5">
          <p className="text-zinc-200 font-semibold text-xs">
            Setup Blog Template
          </p>
        </div>
        <div className="flex item-center justify-between">
          <button
            disabled={isCreating}
            onClick={(e) => {
              createNewBlogTemplatePage();
            }}
            className="pl-1 my-2 bg-lime-600 rounded-lg text-white text-sm w-[70px]"
          >
            Create
          </button>
          {isCreating ? (
            <AiOutlineLoading3Quarters className="float-right text-white w-4 h-auto animate-spin " />
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-between mt-5">
          <p className="text-zinc-200 font-semibold text-xs">Create page with PDF</p>
          
          <div>
            <input
              type="file"
              accept="application/pdf"
              onChange={handlePDFFileChange}
              style={{ display: "none" }}
              id="file-pdf-upload"
            />
            <label htmlFor="file-upload">
              {isPDFImporting ? (
                <AiOutlineLoading3Quarters className="float-right text-white w-4 h-auto animate-spin" />
              ) : (
                <button
                  className="float-right"
                  title="Click to upload file"
                  onClick={() =>
                    document.getElementById("file-pdf-upload").click()
                  }
                >
                  <FaFileImport className="w-4 h-auto text-zinc-300" />
                </button>
              )}
            </label>
            <button onClick={handlePDFFileUpload}>Upload</button>
          </div>
        </div>
        {/* <input
          label="upload pdf"
          type="file"
          accept="application/pdf"
          onChange={handlePDFFileChange}
          className="mb-4 block"
        /> */}
      </div>
    </div>
  );
};

export default Pages;
