// import * as AllComponents from '@radix-ui/themes';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import { useRecoilState } from "recoil";
import StringWrapper from "./workspace/StringWrapper";
import CanvasContext from "../../context/CanvasContext";
import UserContext from "../../context/UserContext";
import { useDrag, useDrop } from "react-dnd";
import { useMergedRefs } from "../../hooks/useMergedRefs";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  selfClosingHTMLTags,
  extractBlogContents,
  blogArchiveCode,
} from "../../helpers/helpers";
import useTrackedSetRecoilState from "../../hooks/useTrackedSetRecoilState";

import { getJSXAndScript } from "../../componentStates/jsxConverter";
import { jsonToCode } from "../../componentStates/codeParser";

const CompEditable = ({
  recoilState,
  parentRecoilState,
  parentChildIndex,
  sectionChildIndex,
  editable,
}) => {
  const key = recoilState?.key;
  const [compState] = useRecoilState(recoilState);
  const setCompState = useTrackedSetRecoilState(recoilState);
  const compRef = useRef(null);
  const [isDragHover, setIsDragHover] = useState(false);
  const { user } = useContext(UserContext);

  const {
    SCREEN,
    screenType,
    setAtomValues,
    pageState,
    hoveredState,
    setHoveredState,
    selectedState,
    setSelectedState,
    setSelectedParentState,
    setSelectedParentChildIndex,
    setHoveredParentState,
    setHoveredParentChildIndex,
    setSelectedCompStyle,
    setSelectedComponentRect,
    setHoveredComponentRect,
    setDropComponentRect,
    setSiteRecord,
    setPagesState,
    dragImage,
    siteRecord,
  } = useContext(CanvasContext);
  const setPageAtomState = useTrackedSetRecoilState(pageState);
  const setParentAtomState = useTrackedSetRecoilState(parentRecoilState);

  const axiosPrivate = useAxiosPrivate();

  // Function to generate the HTML for a new blog card and append it to existing HTML
  function addBlogCard(existingHtml, blogDetails) {
    const { heading, authorName, estimatedReadTime, smallDescription } =
      blogDetails;

    // Create the HTML for the new blog card
    const newBlogCardHtml = `
    <div class="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow">
      <img
        class="w-full h-56 object-cover rounded-md mb-4"
        src="https://via.placeholder.com/500x250"
        alt="Blog post image"
      />
      <h2 class="text-2xl font-bold mb-2">${heading}</h2>
      <p class="text-gray-700 mb-4">${smallDescription}</p>
      <div class="text-gray-500 text-sm mb-4">
        ${authorName} • ${estimatedReadTime}
      </div>
      <a class="text-indigo-600 hover:underline" href="#">Read More</a>
    </div>
  `;

    // Use a regex to find the closing tag of the section where cards are located
    const updatedHtml = existingHtml.replace(
      /(<section class="grid gap-8 lg:grid-cols-2 sm:grid-cols-1">)([\s\S]*?)(<\/section>)/,
      (match, p1, p2, p3) => {
        // Append the new card HTML to the existing cards
        return `${p1}${p2}${newBlogCardHtml}${p3}`;
      }
    );

    return updatedHtml;
  }

  const fetchArchivePage = async (blogDetails) => {
    const { jsx } = getJSXAndScript(siteRecord.devPages.archive.code);
  };

  const createBlogArchivePage = async (blogDetails) => {
    if (!Object.keys(siteRecord.devPages).includes("archive")) {
      const pagePath = "archive";
      const resp = await axiosPrivate.post("/website/page", {
        websiteId: siteRecord._id,
        path: pagePath,
        code: blogArchiveCode,
      });
      if (resp.status == 201 && resp.data?.code) {
        const { code, title, description } = resp.data;
        setSiteRecord((site) => {
          const devPages = { ...site.devPages };
          devPages[pagePath] = { code, title, description };
          return { ...site, devPages };
        });
        setPagesState((curr) => {
          const newStates = { ...curr };
          newStates[pagePath] = undefined;
          return newStates;
        });
      } else {
        throw Error("Unexpected response from server");
      }
    } else {
      // fetchArchivePage(blogDetails);
    }
  };

  function htmlToTailwind(html) {
    const container = document.createElement("div");
    container.innerHTML = html;

    const addClass = (selector, className) => {
      const elements = container.querySelectorAll(selector);
      elements.forEach((element) => {
        element.classList.add(...className.split(" "));
      });
    };

    addClass("h1", "text-4xl font-bold mb-4");
    addClass("h2", "text-3xl font-semibold mb-3");
    addClass("h3", "text-2xl font-semibold mb-3");
    addClass("h4", "text-xl font-medium mb-3");
    addClass("h5", "text-lg font-medium mb-3");
    addClass("p", "text-base leading-relaxed mb-12");
    addClass("ul", "list-disc px-5");
    addClass("ol", "list-decimal px-5");
    addClass("a", "text-blue-500 hover:underline");
    addClass("strong", "font-semibold");
    addClass("em", "italic");
    addClass("span", "inline-block");

    addClass("div", "mb-6");

    addClass("ul li", "mb-2 pl-4");
    addClass("ol li", "mb-2 pl-4");

    const externalLinks = container.querySelectorAll('a[href^="http"]');
    externalLinks.forEach((link) => {
      link.classList.add("text-blue-500", "hover:text-blue-700");
    });

    ["html", "head", "body"].forEach((tag) => {
      const elements = container.querySelectorAll(tag);
      elements.forEach((element) => {
        while (element.firstChild) {
          element.parentNode.insertBefore(element.firstChild, element);
        }
        element.remove();
      });
    });

    return container.innerHTML;
  }

  async function createBlogPageWithUserBlogTemplate(
    userBlogTemplateJson,
    blogData
  ) {
    const convertedJsonToHtml = await jsonToCode(userBlogTemplateJson);

    const container = document.createElement("div");
    container.innerHTML = convertedJsonToHtml.html;

    const documentFragment = container;

    const blogTitleElement = documentFragment.querySelector("#BLOG-TITLE");
    if (blogTitleElement) {
      blogTitleElement.textContent = blogData.Name;
    }

    const blogSubtitleElement =
      documentFragment.querySelector("#BLOG-SUBTITLE");
    if (blogSubtitleElement) {
      blogSubtitleElement.textContent = blogData["Sub Title"];
    }

    const blogImageElement = documentFragment.querySelector("#BLOG-IMAGE");
    if (blogImageElement) {
      blogImageElement.src = blogData["Main Image"];
    }

    const blogPostBody = documentFragment.querySelector("#BLOG-POST-BODY");
    if (blogPostBody) {
      const divToRemove = blogPostBody.querySelector("#DIV-To-REMOVE");
      if (divToRemove) {
        divToRemove.remove();
      }
      blogPostBody.innerHTML = htmlToTailwind(blogData["Post Body"]);
    }

    const updatedHTML = container.innerHTML;

    return updatedHTML;
  }

  const [, dropRef] = useDrop({
    accept: "component",
    drop: async (item, monitor) => {
      setDropComponentRect(null);
      if (monitor.isOver({ shallow: true })) {
        console.log("item item", item);

        let defaultBlogPageTemplate;

        if (siteRecord.devPages["blog-template"]) {
          const defaultBlogData = {
            Name: "How 5 Special Need Care Tips Transform Lives",
            "Created On":
              "Mon Jul 08 2024 06:38:24 GMT+0000 (Coordinated Universal Time)",
            "Updated On":
              "Mon Jul 08 2024 18:12:29 GMT+0000 (Coordinated Universal Time)",
            "Published On":
              "Mon Jul 08 2024 18:12:29 GMT+0000 (Coordinated Universal Time)",
            "Post Body":
              htmlToTailwind(`<div><div><h5>Introduction: The Impact of Special Need Care on Disabled Adults and Families</h5><span><p>Special need care plays a crucial role in transforming lives, bringing hope and joy to disabled adults and their families. It's not just about meeting basic needs but about creating an environment where individuals can thrive. When done right, special need care can significantly improve the quality of life for everyone involved.</p>
          <p>Families with disabled adults often face unique challenges. These challenges can range from finding appropriate care facilities to ensuring their loved ones are engaged and happy. That's where effective special need care comes into play. It provides not only physical support but also emotional and social well-being.</p>
          <p>"Special need care is a lifeline for families navigating the complexities of disability," says Kristin Groves, founder of Harbor Village. It’s about understanding each person's unique needs and fostering an environment that promotes growth and happiness.</p>
          <p>Here are some key impacts of special need care:</p>
          <ul>
          <li><p><strong>Enhanced Quality of Life:</strong> Personalized care plans ensure that each individual's specific needs are met, leading to a better quality of life.</p>
          </li>
          <li><p><strong>Emotional Support:</strong> A loving environment provides the emotional support necessary for disabled adults to feel valued and understood.</p>
          </li>
          <li><p><strong>Community Engagement:</strong> Social activities and community involvement help in building meaningful relationships and reducing feelings of isolation.</p>
          </li>
          <li><p><strong>Peace of Mind for Families:</strong> Knowing that their loved ones are in a safe, nurturing environment gives families peace of mind.</p>
          </li>
          </ul>
          <p>Investing in special need care transforms not only the lives of disabled adults but also brings relief and joy to their families. It's about creating a community where everyone feels supported and valued. For more on how special need care can make a difference, check out <a href="https://harbor-village.webflow.io/">Harbor Village</a>.</p>
          </span></div><div><h5>Understanding Individual Needs in Special Need Care</h5><span><p>Special need care isn't one-size-fits-all; it's about seeing the person, not just the diagnosis. Each individual has unique needs, abilities, and preferences. Understanding these differences is the cornerstone of effective care.</p>
          <p>"To truly care for someone, you must first understand them." This quote from Harbor Village perfectly captures the essence of providing personalized care. It's not just about meeting basic needs but about understanding what makes each person tick.</p>
          <p>Here's why understanding individual needs is so crucial:</p>
          <ul>
          <li><p><strong>Personalized Attention</strong>: Knowing what each person needs allows caregivers to provide more targeted, effective support. This can range from specific dietary requirements to unique communication methods.</p>
          </li>
          <li><p><strong>Improved Well-being</strong>: When caregivers understand an individual's likes and dislikes, they can create a more comfortable and enjoyable environment. This leads to better mental and emotional health.</p>
          </li>
          <li><p><strong>Building Trust</strong>: Understanding individual needs helps build trust between the caregiver and the person receiving care. Trust is essential for effective caregiving.</p>
          </li>
          <li><p><strong>Enhanced Independence</strong>: By recognizing and supporting individual abilities, caregivers can empower disabled adults to be as independent as possible.</p>
          </li>
          </ul>
          <p>As highlighted in the article <a href="https://www.specialneedscare.com/understanding-needs">Why Understanding Individual Needs is Crucial</a>, understanding each person's unique needs enables caregivers to provide the highest quality care. It's about creating a care plan that is as unique as the individual it's designed for.</p>
          <p>Understanding individual needs is the first step in transforming lives through special need care. It's about seeing the person, not just the disability, and tailoring care to meet their unique needs and preferences.</p>
          </span></div><div><h5>Creating a Safe and Loving Environment for Special Need Care</h5><span><p>Creating a safe and loving environment is the heart of effective special need care. It's not just about meeting physical needs but also nurturing emotional well-being. Here are some key strategies that make a significant difference:</p>
          <ul>
          <li><p><strong>Safety First:</strong> A safe environment is crucial. This means ensuring that the space is free from hazards and accessible to everyone. Safety also includes emotional security, where individuals feel valued and respected.</p>
          </li>
          <li><p><strong>Love and Compassion:</strong> Love and compassion form the foundation of special need care. Caregivers should show genuine affection and understanding. As the staff at <a href="https://harbor-village.webflow.io/">Harbor Village</a> say, "To truly care for someone is to first understand them."</p>
          </li>
          <li><p><strong>Routine and Predictability:</strong> Having a consistent routine helps individuals feel safe and secure. It reduces anxiety and helps them know what to expect each day.</p>
          </li>
          <li><p><strong>Personalized Attention:</strong> Every individual is unique. Providing personalized attention ensures that each person's specific needs are met. This can include tailored activities that match their interests and abilities.</p>
          </li>
          <li><p><strong>Positive Reinforcement:</strong> Encouraging positive behavior through praise and rewards can boost self-esteem and promote a sense of accomplishment.</p>
          </li>
          </ul>
          <p>"Creating a safe and loving environment isn't just a goal; it's a necessity," says Kristin Groves, founder of Harbor Village. "It's about building a community where everyone feels included and supported."</p>
          </span></div><div><h5>The Importance of Personalized Care Plans in Special Need Care</h5><span><p>Creating personalized care plans is at the heart of effective special need care. Each individual has their own unique needs, abilities, and preferences. By recognizing these differences, caregivers can tailor their approach to ensure that each person receives the best possible support.</p>
          <p>Understanding and addressing individual needs can make a huge difference in someone's life. When care plans are customized, it means the activities, therapies, and routines are all designed to fit the person's specific requirements. This not only helps in achieving better outcomes but also in enhancing the quality of life for disabled adults.</p>
          <p>"Personalized care plans are not just about meeting basic needs; they're about enriching lives," says Kristin Groves, co-founder of Harbor Village. This approach ensures that every individual receives care that respects their dignity and promotes their well-being.</p>
          <p>Here are some key aspects of creating personalized care plans:</p>
          <ul>
          <li><strong>Assessment of Needs</strong>: Regularly assess the individual's physical, emotional, and social needs.</li>
          <li><strong>Goal Setting</strong>: Establish clear, achievable goals that cater to the individual's abilities and aspirations.</li>
          <li><strong>Flexibility</strong>: Adapt the care plan as the individual's needs and circumstances change.</li>
          <li><strong>Collaboration</strong>: Involve family members, healthcare professionals, and the individuals themselves in the planning process.</li>
          </ul>
          <p>The significance of personalized care plans can't be overstated. They empower individuals by giving them a sense of control and involvement in their care. This level of attention and customization is a key factor in providing high-quality <a href="https://harbor-village.webflow.io/">special need care</a>. It transforms lives by fostering independence, confidence, and happiness.</p>
          </span></div><div><h5>Community and Social Engagement in Special Need Care</h5><span><p>Community and social engagement play a vital role in the quality of life for adults with special needs. When we talk about special need care, it's not just about attending to physical needs but also ensuring emotional and social well-being. Engaging in the community helps individuals feel valued and included.</p>
          <p>"Inclusion is not a strategy to help people fit into the systems and structures which exist in our societies; it is about transforming those systems and structures to make it better for everyone," says Diane Richler.</p>
          <p><strong>Benefits of Community Engagement</strong></p>
          <ul>
          <li><strong>Improved Mental Health:</strong> Participating in social activities can reduce feelings of isolation and depression.</li>
          <li><strong>Skill Development:</strong> Engaging in community events can help develop new skills and hobbies.</li>
          <li><strong>Sense of Belonging:</strong> Feeling part of a community enhances self-esteem and confidence.</li>
          </ul>
          <p><strong>Ways to Foster Community Engagement</strong></p>
          <ol>
          <li><strong>Join Local Clubs and Groups:</strong> Encourage participation in clubs or groups that align with their interests.</li>
          <li><strong>Volunteer Opportunities:</strong> Find volunteer opportunities within the community to promote a sense of purpose.</li>
          <li><strong>Social Events:</strong> Organize social gatherings, such as picnics, movie nights, or game days.</li>
          <li><strong>Educational Workshops:</strong> Attend workshops that offer new learning experiences and skill-building activities.</li>
          <li><strong>Support Groups:</strong> Join support groups for both individuals and their families to share experiences and advice.</li>
          </ol>
          <p>Community and social engagement can transform lives by providing a sense of purpose and belonging. At <a href="https://harbor-village.webflow.io/">Harbor Village</a>, we emphasize the importance of special need care that goes beyond just physical well-being. By integrating these strategies, we create a more inclusive, supportive environment for all.</p>
          </span></div><div><h5>Training and Empowering Caregivers for Special Need Care</h5><span><p>Caring for adults with disabilities requires more than just compassion; it requires proper training and support. Empowering caregivers is a vital part of providing top-notch special need care. When caregivers are well-trained, they can better meet the unique needs of each individual, leading to happier and healthier lives for those they care for.</p>
          <p>One essential aspect of caregiver training is understanding the specific disabilities and challenges faced by the individuals they care for. Tailored training programs help caregivers learn how to handle various situations effectively. This not only boosts their confidence but also ensures that they can provide the best care possible.</p>
          <p>“Training is the backbone of quality care,” says Kristin Groves, founder of Harbor Village. “It equips caregivers with the skills they need to support our clients fully.”</p>
          <p>Here are some key strategies for training and empowering caregivers:</p>
          <ul>
          <li><strong>Providing comprehensive training programs</strong>: These programs should cover everything from basic care techniques to advanced medical procedures.</li>
          <li><strong>Offering ongoing education</strong>: Continuous learning opportunities help caregivers stay updated with the latest in special need care.</li>
          <li><strong>Emphasizing emotional support</strong>: Caregivers need to be emotionally resilient. Training should include strategies for managing stress and preventing burnout.</li>
          <li><strong>Building a supportive community</strong>: Creating a network of caregivers who can share experiences and advice is crucial for mutual support.</li>
          </ul>
          <p>By investing in caregiver training, we ensure that adults with disabilities receive the best care possible. This not only transforms their lives but also provides peace of mind for their families. As caregivers become more skilled and confident, the overall quality of special need care improves, paving the way for a brighter future for everyone involved.</p>
          </span></div><div><h5>Conclusion: Transforming Lives Through Special Need Care</h5><span><p>In conclusion, implementing effective special need care strategies can truly transform lives. By understanding individual needs, we create a foundation for exceptional care. Recognizing each person's unique abilities helps us tailor a care plan that truly fits them. As we discussed, a safe and loving environment is crucial. It ensures that disabled adults feel secure and valued. This sense of safety directly impacts their overall well-being.</p>
          <p>Personalized care plans are another key element. They allow us to address specific requirements, ensuring that every individual receives the attention they deserve. Coupled with community and social engagement, these care plans help enhance the quality of life. Social activities and community involvement provide a sense of belonging and purpose, which is vital for mental and emotional health.</p>
          <p>"The importance of community cannot be overstated," says Kristin Groves, founder of Harbor Village. Proper training and support for caregivers also play a significant role. Well-trained caregivers can provide the best possible care, making a huge difference in the lives of those they serve.</p>
          <p>To summarize, the impact of these special need care tips is profound:</p>
          <ul>
          <li>Understanding individual needs</li>
          <li>Creating a safe and loving environment</li>
          <li>Developing personalized care plans</li>
          <li>Engaging in community and social activities</li>
          <li>Training and empowering caregivers</li>
          </ul>
          <p>By focusing on these strategies, we can ensure that adults with disabilities lead fulfilling, abundant lives. This approach not only benefits the individuals but also provides peace of mind for their families. Special need care is more than just assistance; it's about enhancing lives and building a supportive community. <a href="https://harbor-village.webflow.io/">Learn more about how special need care transforms lives</a>.</p>
          </span></div></div><span><p>At Harbor Village, we are dedicated to offering unparalleled services in special need care. Our commitment goes beyond mere assistance; we strive to enhance the quality of life for every individual we serve. Our holistic approach ensures that each client receives personalized care tailored to their unique needs. We understand the challenges faced by those requiring special need care, and our expert team is equipped with the knowledge and compassion to meet these challenges head-on. By integrating the latest methodologies and a compassionate touch, Harbor Village stands out as a beacon of hope and support. Whether you or your loved one requires daily assistance or specialized medical care, we are here to provide top-notch service that prioritizes dignity and respect. Visit our website to learn more about our comprehensive services and how we can assist you in achieving a higher standard of living. With Harbor Village, exceptional care is not just our mission, it's our promise. Explore the potential of a better care experience with us and discover why we are the trusted choice for special need care.</p>
          </span>`),
            "Main Image":
              "https://uploads-ssl.webflow.com/6683f430367555b43b35a9d0/668c2c03d7d4f7f3379414ea_pexels-photo-4057064.webp",
            "Sub Title":
              "Empowering Disabled Adults: Five Key Care Strategies Revealed",
            "Post outline":
              htmlToTailwind(`<div>Special need care profoundly impacts lives by enhancing the quality of life for disabled adults and their families. Key care strategies include understanding individual needs, creating a safe and loving environment, developing personalized care plans, promoting community engagement, and empowering caregivers through training. These approaches foster independence, emotional well-being, and a sense of belonging, transforming lives and providing peace of mind for families.</div><ul><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Introduction: The Impact of Special Need Care on Disabled Adults and Families
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Understanding Individual Needs in Special Need Care
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Creating a Safe and Loving Environment for Special Need Care
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    The Importance of Personalized Care Plans in Special Need Care
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Community and Social Engagement in Special Need Care
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Training and Empowering Caregivers for Special Need Care
                  </li><li style='color: '#fff', font-size: '16px', margin-bottom: '12px', font-weight: 700'>
                    Conclusion: Transforming Lives Through Special Need Care
                  </li></ul>`),
          };
          defaultBlogPageTemplate = await createBlogPageWithUserBlogTemplate(
            siteRecord.devPages["blog-template"].code,
            defaultBlogData
          );
        }

        const newCompState = item?.asset?.create(defaultBlogPageTemplate);

        if (!item?.asset?.categ?.includes("sections")) {
          if (item.asset.categ.includes("blog")) {
            const blogDetails = extractBlogContents(item.asset.htmlCode);
            createBlogArchivePage(blogDetails);
          }

          setCompState((prev) => ({
            ...prev,
            children: [...prev.children, newCompState],
          }));
        } else {
          if (key === pageState.key) {
            setPageAtomState((prev) => {
              const newChildren = [...prev.children];
              newChildren.push(newCompState);
              return { ...prev, children: newChildren };
            });
          } else {
            setPageAtomState((prev) => {
              const newChildren = [...prev.children];
              newChildren.splice(sectionChildIndex + 1, 0, newCompState);
              return { ...prev, children: newChildren };
            });
          }
        }
        setSelectedState(newCompState);
      }
    },
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        setIsDragHover(true);
      } else {
        setIsDragHover(false);
      }
    },
  });

  // drag drop hover state functions. it sets the current comp to the hovered drop zone
  const setDropZone = () => {
    if (compRef.current) {
      setDropComponentRect(compRef.current.getBoundingClientRect());
    }
  };
  const removeDropZone = () => {
    if (key == pageState.key) {
      setDropComponentRect(null);
    }
  };

  useEffect(() => {
    if (isDragHover) {
      setDropZone();
    } else {
      removeDropZone();
    }
  }, [isDragHover]);

  // DnD within parent
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: parentRecoilState.key,
      item: () => {
        preview(dragImage, { captureDraggingState: true });
        return { recoilState, parentChildIndex };
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [recoilState, parentChildIndex, dragImage]
  );

  const [, dropRefParent] = useDrop({
    accept: parentRecoilState.key,
    drop: (item, monitor) => {
      if (parentChildIndex === item.parentChildIndex) {
        return;
      }
      setParentAtomState((prev) => {
        const newChildren = [...prev.children];
        newChildren.splice(item.parentChildIndex, 1);
        newChildren.splice(parentChildIndex, 0, item.recoilState);
        return { ...prev, children: newChildren };
      });
    },
    hover: (item, monitor) => {},
  });

  // this collects all the atom values inside a mapping to be used later
  // for converting atom to values in recursive fashion
  useEffect(() => {
    setAtomValues((prev) => {
      const newAtomValues = { ...prev };
      newAtomValues[recoilState.key] = compState;
      return newAtomValues;
    });
  }, [compState]);

  // this computes current comp style for selected comp as source of truth to display on props panel
  useEffect(() => {
    if (selectedState.key == key && compRef.current) {
      const style = window.getComputedStyle(compRef.current);
      setSelectedCompStyle(style);
    }
  }, [compRef, selectedState]);

  // functions for onMouseOver and onMouseLeave for hover and onClick for selection
  const setHoveredStateToCurrent = (e) => {
    e.stopPropagation();
    setHoveredState(recoilState);
  };

  const setHoveredStateToNull = (e) => {
    e.stopPropagation();
    setHoveredState(pageState);
  };

  const setSelectedCompToCurr = (e) => {
    e.stopPropagation();
    setSelectedState(hoveredState);
  };

  // changing state after selected comp changes
  useEffect(() => {
    if (selectedState.key == key) {
      if (parentRecoilState?.key) {
        setSelectedParentState(parentRecoilState);
        setSelectedParentChildIndex(parentChildIndex);
      }
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState.key == key) {
      setTimeout(() => {
        if (compRef.current) {
          setSelectedComponentRect(compRef.current.getBoundingClientRect());
        }
      }, 50);
    }
  }, [selectedState, compState, compRef]);

  useEffect(() => {
    if (hoveredState && hoveredState.key == key) {
      if (parentRecoilState?.key) {
        setHoveredParentState(parentRecoilState);
        setHoveredParentChildIndex(parentChildIndex);
      }
      if (compRef.current) {
        setHoveredComponentRect(compRef.current.getBoundingClientRect());
      }
    }
  }, [hoveredState]);

  const mergedRefs = useMergedRefs(compRef, dropRef, dragRef, dropRefParent);
  const mergedRefsForPageState = useMergedRefs(compRef, dropRef);

  const { compType, compName, compProps, style, className, children } =
    compState;
  const [filteredClassName, setFilteredClassName] = useState(className);

  useEffect(() => {
    let newClassName = className;
    if (screenType == "tablet") {
      // Remove classes starting with lg, xl, 2xl
      newClassName = newClassName.replace(
        /\blg:[^ ]*\b|\bxl:[^ ]*\b|\b2xl:[^ ]*\b/g,
        ""
      );
    } else if (screenType == "mobile") {
      // Remove classes starting with md, lg, xl, 2xl
      newClassName = newClassName.replace(
        /\bsm:[^ ]*\b|\bmd:[^ ]*\b|\blg:[^ ]*\b|\bxl:[^ ]*\b|\b2xl:[^ ]*\b/g,
        ""
      );
    }
    newClassName = newClassName.replace(/w-screen/g, "w-full");
    newClassName = newClassName.replace(
      /h-screen/g,
      `h-[${SCREEN[screenType].height}px]`
    );
    setFilteredClassName(newClassName);
  }, [className, screenType]);
  const [filteredCompProps, setFilteredCompProps] = useState(compProps);
  useEffect(() => {
    if (compName === "a") {
      const { href, ...compProps2 } = compProps;
      compProps2["data-aos"] = undefined;
      setFilteredCompProps(compProps2);
    } else {
      const { ...compProps2 } = compProps;
      if (compProps2["data-aos"]) compProps2["data-aos"] = null;
      setFilteredCompProps(compProps2);
    }
  }, [compProps, compName]);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  // // to skip render if the element name doesn't exist
  // if (compType === 'radix' && !AllComponents[compName]) {
  //   console.log(compName + ` not a valid ${compType} component. Skipped for rendering.`);
  //   return <></>;
  // }

  // const DynamicComponent = compType === 'jsx'? compName : AllComponents[compName];
  const DynamicComponent = compName === "form" ? "div" : compName;
  const selfClosing = selfClosingHTMLTags.includes(compName);

  return (
    <>
      {!selfClosing ? (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={filteredClassName}
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
        >
          {children.map((child, index) => {
            return typeof child === "string" ? (
              editable ? (
                <StringWrapper
                  key={child}
                  parentRecoilState={recoilState}
                  childListIndex={index}
                >{` ${child}`}</StringWrapper>
              ) : (
                child
              )
            ) : (
              <CompEditable
                key={child.key}
                recoilState={child}
                parentRecoilState={recoilState}
                parentChildIndex={index}
                sectionChildIndex={
                  sectionChildIndex === -1 ? index : sectionChildIndex
                }
                editable={editable}
              />
            );
          })}
        </DynamicComponent>
      ) : compName === "img" ? (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={
            isImgLoaded ? filteredClassName : "bg-zinc-400 " + filteredClassName
          }
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
          editable={editable ? "true" : "false"}
          onLoad={() => {
            setIsImgLoaded(true);
          }}
          onError={() => {
            setIsImgLoaded(true);
          }}
        />
      ) : (
        <DynamicComponent
          ref={
            !editable
              ? null
              : key !== pageState.key
              ? mergedRefs
              : mergedRefsForPageState
          }
          {...filteredCompProps}
          style={
            !isDragging ? style : { ...style, opacity: "40%", cursor: "none" }
          }
          className={filteredClassName}
          onMouseOver={
            !editable || key == pageState.key
              ? () => {}
              : setHoveredStateToCurrent
          }
          onMouseOut={
            !editable || key == pageState.key ? () => {} : setHoveredStateToNull
          }
          onClick={
            !editable || key == pageState.key ? () => {} : setSelectedCompToCurr
          }
          onDragLeave={editable && removeDropZone}
          editable={editable}
        />
      )}
    </>
  );
};

export default CompEditable;
